import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './views/HomePage/HomePage';
import LobbyPage from './views/LobbyPage/LobbyPage';
import CreateGameForm from "./views/CreateGameForm/CreateGameForm";
import { GameProvider } from "./GameContext";
import JoinGameForm from "./views/JoinGameForm/JoinGameForm";
import GamePage from "./views/GamePage/GamePage";
import { WebSocketProvider } from "./server/WebSocketProvider";
import MusicToggleButton from './components/MusicToggleButton/MusicToggleButton';

function MyRoutes() {

    const ConditionalMusicToggleButton = () => {
        let location = useLocation();
        const allowedPaths = ['/create-game', '/join-game', '/lobby'];

        if (allowedPaths.includes(location.pathname)) {
            return <MusicToggleButton />;
        }
        if(window.stopMusic) window.stopMusic();

        return null;
    }

    return (
        <GameProvider>
            <WebSocketProvider>
                <Router>
                <ConditionalMusicToggleButton />
                    <Routes>
                        <Route path="/" Component={HomePage} />
                        <Route path="/create-game" Component={CreateGameForm} />
                        <Route path="/join-game/:gameIdParam?" Component={JoinGameForm} />
                        <Route path="/lobby" Component={LobbyPage} />
                        <Route path="/game" Component={GamePage} />
                    </Routes>
                </Router>
            </WebSocketProvider>
        </GameProvider>
    );
}

export default MyRoutes;
