import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from "react-bootstrap/Button";

import Onboarding from '../../layouts/Onboarding/Onboarding';

function HomePage() {
    const navigate = useNavigate();

    const createGame = () => {
        // Logic to create a new game and navigate to the lobby page
        if (window.startMusic) window.startMusic();
        navigate(`/create-game`);
    };

    const joinGame = () => {
        // Logic to join an existing game and navigate to the lobby page
        navigate(`/join-game`);
    };

    const MenuItems = [
        (<Button
            className="onboarding__button"
            variant="outline-dark"
            onClick={createGame}>
            Create New Game
        </Button>)
        ,
        (<Button
            className="onboarding__button"
            variant="outline-dark"
            onClick={joinGame}>
            Join Game
        </Button>)
    ]


    return (<Onboarding menuItems={MenuItems} />);
}

export default HomePage;
