import GameCard from "../../GameCard/GameCard";
import React from "react";
import CardDTO from "../../../../models/CardDTO";
import './PlayerInfoCardView.css';
interface PlayerInfoCardViewProps {
    playerCards: CardDTO[];
}

const PlayerInfoCardView: React.FC<PlayerInfoCardViewProps> = ({ playerCards }) => {
    return (
        <div className="player-info-cards-container">
            {playerCards.map((card) => {
                return (
                    <div className="card-container">
                        <GameCard gameCard={card}/>
                    </div>
                );
            })}

        </div>
    );
};

export default PlayerInfoCardView;