import CardDTO from './CardDTO';
import PlayerDTO from './PlayerDTO';

interface GameEventDTO {
    timeStamp: string;
    type: GameEventType;
    playerId: string;
    referencedCardId: string;
    costPaid: number;
    compensationPaid: number;
    compensatedPlayerId: string;
    coinsReceived: number;
    cardsTradedForExpedition: CardDTO[];
    playersTaxed: Record<string, number>;
    playersWithTaxReturn: string[];
    winners: string[];
}

enum GameEventType {
    PLAYER_JOINED = 'PLAYER_JOINED',
    PLAYER_TURN_STARTED = 'PLAYER_TURN_STARTED',
    SECONDARY_PLAYER_TURN_STARTED = 'SECONDARY_PLAYER_TURN_STARTED',
    DISCARD_PILE_SHUFFLED_INTO_DRAW_PILE = 'DISCARD_PILE_SHUFFLED_INTO_DRAW_PILE',
    CARD_DRAWN_INTO_HARBOR = 'CARD_DRAWN_INTO_HARBOR',
    EXPEDITION_DRAWN = 'EXPEDITION_DRAWN',
    SHIP_REPELLED = 'SHIP_REPELLED',
    DISCOVERY_ENDED = 'DISCOVERY_ENDED',
    PERSON_HIRED = 'PERSON_HIRED',
    SHIP_TAKEN = 'SHIP_TAKEN',
    EXPEDITION_CLAIMED = 'EXPEDITION_CLAIMED',
    TRADE_AND_HIRE_ENDED = 'TRADE_AND_HIRE_ENDED',
    TAXES_ENFORCED = 'TAXES_ENFORCED',
    JESTER_COINS_RECEIVED = 'JESTER_COINS_RECEIVED',
    ADMIRAL_COINS_RECEIVED = 'ADMIRAL_COINS_RECEIVED',
    TRADER_COINS_RECEIVED = 'TRADER_COINS_RECEIVED',
    GAME_ENDED = 'GAME_ENDED'
}


function displayValue(event: GameEventDTO | null, players: PlayerDTO[]): string {
    if (event === null) {
        return '';
    }
    const startLog = `${timeStampToTime(event.timeStamp)} - ${playerIdToName(event.playerId)} `;

    switch (event.type) {
        case GameEventType.ADMIRAL_COINS_RECEIVED:
            return `${startLog} received ${event.coinsReceived} coins for their Admiral(s).`;
        case GameEventType.CARD_DRAWN_INTO_HARBOR:
            return `${startLog} drew a card into the harbor.`;
        case GameEventType.DISCARD_PILE_SHUFFLED_INTO_DRAW_PILE:
            return `The draw pile was exhausted. The discard pile has been shuffled into a new draw pile.`;
        case GameEventType.DISCOVERY_ENDED:
            return `${startLog} has ended their Discovery phase.`;
        case GameEventType.EXPEDITION_CLAIMED:
            return `${startLog} has claimed an Expedition. ${event.cardsTradedForExpedition.length} cards were traded and ${event.coinsReceived} coins rewarded.`;
        case GameEventType.EXPEDITION_DRAWN:
            return `${startLog} has drawn a new Expedition card into the Expedition deck.`;
        case GameEventType.GAME_ENDED:
            return `The game has ended. The winners are: ${event.winners}`;
        case GameEventType.JESTER_COINS_RECEIVED:
            return `${startLog} has been rewarded ${event.coinsReceived} for their Jester(s).`;
        case GameEventType.PERSON_HIRED:
            let hiredPersonString = `${startLog} has hired a Person.`;
            if (event.compensatedPlayerId) {
                let s2 = `Compensation of ${event.compensationPaid} coin(s) was paid to ${event.compensatedPlayerId}.`;
                return hiredPersonString + " " + s2;
            }
            return hiredPersonString;
        case GameEventType.PLAYER_JOINED:
            return `${startLog} joined the game.`;
        case GameEventType.PLAYER_TURN_STARTED:
            return `${startLog}'s turn started.`;
        case GameEventType.SECONDARY_PLAYER_TURN_STARTED:
            return `${startLog} has current action as secondary player.`;
        case GameEventType.SHIP_REPELLED:
            return `${startLog} repelled a ship.`;
        case GameEventType.SHIP_TAKEN:
            let shipTakenString = `${startLog} has taken a ship for a reward of ${event.coinsReceived}.`;
            if (event.compensatedPlayerId) {
                let s2 = `Compensation of ${event.compensationPaid} coin(s) was paid to ${event.compensatedPlayerId}.`;
                return shipTakenString + " " + s2;
            }
            return shipTakenString;
        case GameEventType.TAXES_ENFORCED:
            const hasMultiplePlayersTaxReturn = event.playersWithTaxReturn.length > 1;
            const hasMultiplePlayersTaxed = Object.keys(event.playersTaxed).length > 1;
            let taxesString = `${startLog} enforced taxes. Player${hasMultiplePlayersTaxReturn ? "s" : ""} 
            ${multiplePlayerNames(event.playersWithTaxReturn)} ${hasMultiplePlayersTaxReturn ? "were each" : "was"}
            rewarded 1 coin. 
            ${hasMultiplePlayersTaxed ? "The following player(s) was/were taxed: " + multiplePlayerNames(Object.keys(event.playersTaxed)) : "No players were taxed."}`;
            if (event) {
                let entries = Object.entries(event.playersTaxed);
                entries.forEach((value, key) => taxesString += `${key}=${value} coins, `)
            }
            return taxesString;
        case
            GameEventType.TRADER_COINS_RECEIVED
            :
            return `${startLog} received ${event.coinsReceived} coins for their Trader(s).`;
        case
            GameEventType.TRADE_AND_HIRE_ENDED
            :
            return `${startLog} ended the Trade & Hire phase.`;
    }

    function playerIdToName(playerId: string) {
        let player = players.find(p => p.id === playerId);
        if (player) {
            return player.name;
        }
        return playerId;
    }

    function multiplePlayerNames(players: string[]) {
        return players.map((player, index) => {
            return playerIdToName(player);
        }).toString().replace(/,/g, ", ");
    }

    function timeStampToTime(timeStamp: string) {
        return timeStamp.split('.')[0];
    }
}



export {
    GameEventType,
    displayValue
};
export type { GameEventDTO };

