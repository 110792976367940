import React from 'react';
import "./Header.css";

function Header() {
    return (
        <header className='header'>
            <h1 className='title'>Port Salut</h1>
        </header>
    );
}

export default Header;
