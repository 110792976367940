interface GameActionDTO {
    id: string;
    actionType: ActionType;
    referencedCardId: string | null;
}

enum ActionType {
    CLAIM_EXPEDITION = "CLAIM_EXPEDITION",
    DRAW_CARD = "DRAW_CARD",
    END_DISCOVERY_PHASE = "END_DISCOVERY_PHASE",
    END_SECONDARY_PLAYER_TURN = "END_SECONDARY_PLAYER_TURN",
    END_TRADE_AND_HIRE_PHASE = "END_TRADE_AND_HIRE_PHASE",
    ENFORCE_TAXES = "ENFORCE_TAXES",
    HIRE_PERSON = "HIRE_PERSON",
    REPEL_SHIP = "REPEL_SHIP",
    TAKE_SHIP = "TAKE_SHIP",
}

// Create a mapping from ActionType to display values
const actionTypeDisplayMap: Record<ActionType, string> = {
    [ActionType.CLAIM_EXPEDITION]: 'Claim Expedition',
    [ActionType.DRAW_CARD]: 'Draw Card',
    [ActionType.END_DISCOVERY_PHASE]: 'End Discovery Phase',
    [ActionType.END_SECONDARY_PLAYER_TURN]: 'End Secondary Player Turn',
    [ActionType.END_TRADE_AND_HIRE_PHASE]: 'End Trade and Hire Phase',
    [ActionType.ENFORCE_TAXES]: 'Enforce Taxes',
    [ActionType.HIRE_PERSON]: 'Hire Person',
    [ActionType.REPEL_SHIP]: 'Repel Ship',
    [ActionType.TAKE_SHIP]: 'Take Ship'
};

function displayValue(actionType: ActionType|null): string {
    if (actionType === null) {
        return '';
    }
    return actionTypeDisplayMap[actionType];
}

export {ActionType, displayValue};
export default GameActionDTO;
