import React from 'react';
import GameActionDTO from "../../models/GameActionDTO";


import "./BottomMenu.css";
import PlayerActions from '../game/PlayerActions/PlayerActions';

interface PlayerActionsProps {
    availableActions: GameActionDTO[];
}

const BottomMenu: React.FC<PlayerActionsProps> = ({ availableActions }) => {
    return (
        <div className="bottom-menu">
            <PlayerActions availableActions={availableActions}></PlayerActions>
        </div>
    );
};

export default BottomMenu;