
export type Config = {
    backendUrl: string;
    brokerUrl: string;
};

export async function loadConfig(): Promise<Config | undefined> {
    const configUrl = document.getElementById('config')?.getAttribute('data-file') || '';

    // console.log("Config Url: " + window.location.origin + configUrl);

    try {
        if (!configUrl) {
            console.error("Configuration missing!");
        }
        const response = await fetch(window.location.origin + configUrl);
        // console.log("Fetched config: " + response);

        if (response.ok) {
            let loadedConfig = await response.json();
            return {backendUrl: loadedConfig.backendUrl, brokerUrl: loadedConfig.brokerUrl};
        } else {
            console.error('Failed to load configuration data.');
        }
    } catch (error) {
        console.error('An error occurred while fetching configuration data:', error);
    }
}