import React from 'react';
import { displayValue, GameEventDTO } from "../../../models/GameEventDTO";
import PlayerDTO from '../../../models/PlayerDTO';
import style from './GameEventList.module.css';

interface Props {
    gameEvents: GameEventDTO[];
    players: PlayerDTO[];
}

const GameEventList: React.FC<Props> = ({ gameEvents, players }) => {
    const reversedMappedWithoutMutating = [...gameEvents].reverse().map(event => {
        return {
            ...event,
            value: displayValue(event, players)
        }
    });
    return (
        <div className={style.container}>
            <div>
                {reversedMappedWithoutMutating.map((event, index) => (
                    <p className={style.row} key={index}>{displayValue(event ?? null, players)}</p>
                ))}
            </div>
        </div>
    );
};

export default GameEventList;
