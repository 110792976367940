// WebSocketProvider.tsx

import React, { createContext, useContext, useEffect, useState } from 'react';
import { Client } from '@stomp/stompjs';
import { Config, loadConfig } from '../config/ConfigLoader';

type WebSocketContextType = {
    stompClient: Client;
};

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

export function useWebSocket(): WebSocketContextType {
    const context = useContext(WebSocketContext);
    if (!context) {
        throw new Error('useWebSocket must be used within a WebSocketProvider');
    }
    return context;
}

type WebSocketProviderProps = {
    children: React.ReactNode;
};

export function WebSocketProvider({ children }: WebSocketProviderProps): JSX.Element {
    const [stompClient, setStompClient] = useState<Client>();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        const initClient = async () => {
            try {
                const config = await loadConfig();

                if (!config || !config.brokerUrl) {
                    console.error('Configuration issue - no broker URL provided.');
                    return;
                }

                const client = new Client({
                    brokerURL: config.brokerUrl,
                    // ... other client configuration ...
                });

                client.onConnect = () => {
                    console.log('Connected to ws endpoint.');
                    setStompClient(client);
                };

                client.onStompError = (frame) => {
                    console.error('Stomp error occurred:', frame.headers.message);
                };

                client.onWebSocketError = (event) => {
                    console.error('WebSocketError occurred:', event.message);
                };

                client.onWebSocketClose = (evt: CloseEvent) => {
                    console.log('WebSocket closed:', evt.reason);
                };

                client.activate();
                setInitialized(true);
            } catch (error) {
                console.error('Error initializing WebSocket client:', error);
            }
        };

        initClient();
    }, []);

    if (!initialized || !stompClient) {
        return <div>WebSocket initializing...</div>;
    }

    return (
        <WebSocketContext.Provider value={{ stompClient }}>
            {children}
        </WebSocketContext.Provider>
    );
}
