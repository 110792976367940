import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getGameState, startGame } from '../../server/RestApiClient';
import { useGame } from '../../GameContext';
import { Phase } from '../../models/GameDTO';
import Button from "react-bootstrap/Button";
import { useWebSocket } from "../../server/WebSocketProvider";

import "./LobbyPage.css";
import GameEventList from "../../components/game/EventLog/GameEventList";

function LobbyPage() {
    const navigate = useNavigate();
    const { gameId, player, setPlayer, setGameId, gameState, setGameState } = useGame();
    const [copied, setCopied] = useState(false);
    const { stompClient } = useWebSocket();

    async function fetchGameState() {
        // TODO: Use socket endpoint for this?
        const storedGameId = await localStorage.getItem('gameId');
        console.log("GameId: ", storedGameId); // Here we print the actual gameId

        if (storedGameId) {
            console.log("Fetching game state...");
            let gameState = await getGameState(storedGameId);
            setGameId(storedGameId);
            console.log("Game id: " + gameId); //  Here the gameId is null
            setGameState(gameState);
            let playerName = localStorage.getItem('playerName');
            setPlayer(gameState.players.find(value => value.name === playerName) ?? null)
            console.log("Game state fetched!");
        }
    }

    // Update UI with fetched data
    useEffect(() => {
        if (gameState) {
            // Update UI elements with gameState

            // Check if the phase changed to something other than LOBBY
            if (gameState.phase !== Phase.LOBBY) {
                navigate('/game'); // Route to GamePage when phase changes
            }
        }
    }, [gameState]);

    function subscribe() {
        let localGameId = localStorage.getItem("gameId");
        console.log("Subscribing to updates for game" + localGameId);

        stompClient.subscribe(`/socket/game/${localGameId}/update`, (message) => {
            console.log("Received game data for gameId: ", localGameId);
            const gameData = JSON.parse(message.body);
            // Handle game data update (e.g., update UI)
            setGameState(gameData);
        },
            { id: "lobby-page-subscription" });
    }

    // Function to establish the WebSocket connection and return a Promise
    async function establishWebSocketConnection(): Promise<void> {
        if (!stompClient.connected) {
            console.log("Re-establishing WebSocket connection...");

            // Create a Promise that resolves when the connection is established
            const connectionPromise = new Promise<void>((resolve) => {
                stompClient.onConnect = () => {
                    console.log("WebSocket connection established.");
                    resolve(); // Resolve the Promise when connected
                };
            });

            // Activate the WebSocket client
            stompClient.activate();

            // Wait for the connection to be established
            await connectionPromise;
            console.log("Stomp client connected.");
        }
    }

    useEffect(() => {
        establishWebSocketConnection()
            .then(() => fetchGameState())
            .then(() => subscribe());

        return () => {
            if (stompClient.connected) {
                stompClient.unsubscribe(`/socket/game/${gameId}/update`);
            }
        }
    }, []);

    async function handleStartGame() {
        if (gameId != null) {
            let updatedGameState = await startGame(gameId);
            setGameState(updatedGameState);
            navigate('/game');
        }
    }

    function copyGameLink() {
        const gameLink = `${window.location.origin}/join-game/${gameId}`;
        if (!navigator.clipboard) {
            const textField = document.createElement('textarea');
            textField.innerText = gameLink;
            document.body.appendChild(textField);
            textField.select();
            document.execCommand('copy');
            textField.remove();
            setCopied(true);
        } else {
            navigator.clipboard.writeText(gameLink)
                .then(() => setCopied(true))
                .catch(() => console.error("Could not copy game link to clipboard."));
        }
    }

    return (
        <div className="lobby-page">
            <div className="lobby-page__box">
                <div className="lobby-page__content">
                    <h2 className="lobby-page__title">Game Lobby</h2>
                    {gameState?.players.map((player) => (
                        <p className="lobby-page__player-name" key={player.id}>
                            {player.name}
                        </p>
                    ))}
                </div>
                <div>
                    <Button className="lobby-page__start-game-button" variant="dark" onClick={handleStartGame}>
                        Start Game
                    </Button>
                    <Button className="lobby-page__share-game-button" onClick={copyGameLink} variant="success">
                        {copied ? 'Copied!' : 'Share Game'}
                    </Button>
                </div>
            </div>
            {gameState && gameState.gameEvents && gameState.players && (
                    <div className="lobby-page__game-event-list">
                        <GameEventList gameEvents={gameState.gameEvents} players={gameState.players} />
                    </div>
                )}
        </div>
    );
}

export default LobbyPage;
