interface CardDTO {
    id: string;
    cost: number;
    reward: number;
    color: Color;
    type: Type;
    victoryPoints: number;
    noSwords: number;
    taxReliefCondition: TaxReliefCondition;
    requirements: Type[];
}

enum TaxReliefCondition {
    MAX_SWORDS = 'MAX_SWORDS',
    MIN_POINTS = 'MIN_POINTS',
}


enum Type {
    ADMIRAL = "ADMIRAL",
    CAPTAIN = "CAPTAIN",
    EXPEDITION = "EXPEDITION",
    GOVERNOR = "GOVERNOR",
    JACK_OF_ALL_TRADES = "JACK_OF_ALL_TRADES",
    JESTER = "JESTER",
    MADEMOISELLE = "MADEMOISELLE",
    PIRATE = "PIRATE",
    PRIEST = "PRIEST",
    SAILOR = "SAILOR",
    SETTLER = "SETTLER",
    SHIP = "SHIP",
    TAX_INCREASE = "TAX_INCREASE",
    TRADER = "TRADER",
}


enum Color {
    GREEN = 'GREEN',
    BLUE = 'BLUE',
    RED = 'RED',
    BLACK = 'BLACK',
    YELLOW = 'YELLOW',
}


export {TaxReliefCondition, Type, Color};
export default CardDTO;

