import React from 'react';
import PlayerDTO from '../../../models/PlayerDTO'; // Adjust the import path as needed
import PlayerInfo from './PlayerInfo'; // Adjust the import path as needed

interface PlayerListProps {
    players: PlayerDTO[];
}

const PlayerList: React.FC<PlayerListProps> = ({ players }) => {
    return (
        <div className="player-list">
            {players.map((player) => (
                <PlayerInfo key={player.id} player={player} />
            ))}
        </div>
    );
};

export default PlayerList;
