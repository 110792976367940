import React from 'react';
import PlayerDTO, {getSwords, getVictoryPoints} from '../../../models/PlayerDTO';
import CardDTO, {Type} from "../../../models/CardDTO"; // Adjust the import path as needed
import './PlayerInfo.css';
import PlayerInfoCardView from "./CardView/PlayerInfoCardView";
import {Card} from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";

interface PlayerInfoProps {
    player: PlayerDTO;
}

const PlayerInfo: React.FC<PlayerInfoProps> = ({player}) => {

    const cardTypes = player.cards.map((card: CardDTO) => card.type).join(', ');

    const cardGroups: { [color: string]: number } = player.cards.reduce(
        (groups: { [color: string]: number }, card) => {
            if (card.type === Type.TRADER) {
                const color = card.color; // Adjust this based on how your cards are structured
                groups[color] = (groups[color] || 0) + 1;
            }
            return groups;
        },
        {}
    );

    return (
        <div className="player-info-container">
            <div className={`player-frame ${player.mainPlayer ? 'main-player' : ''}
            ${player.secondaryPlayer ? 'secondary-player' : ''}`}>
                <Card className="player-info-card">
                    <CardHeader className="player-info-card-header">{player.name}</CardHeader>
                    <table>
                        <thead>
                            <tr>
                                <th><img className="symbol-img" src={`/symbols/coin.svg`} // Update the path accordingly
                                         alt="coins"/></th>
                                <th><img className="symbol-img" src={`/symbols/shield.png`} // Update the path accordingly
                                         alt="points"/></th>
                                <th><img className="symbol-img" src={`/symbols/swords.png`} // Update the path accordingly
                                         alt="swords"/></th>
                                <th><img className="symbol-img" src={`/symbols/house.png`} // Update the path accordingly
                                         alt="settlers"/></th>
                                <th><img className="symbol-img" src={`/symbols/cross.png`} // Update the path accordingly
                                         alt="priests"/></th>
                                <th><img className="symbol-img" src={`/symbols/anchor.png`} // Update the path accordingly
                                         alt="captains"/></th>
                                <th><img className="symbol-img"
                                         src={`/symbols/jack-of-all-trades.svg`} // Update the path accordingly
                                         alt="jacks"/></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{player.noCoins}</td>
                                <td>{getVictoryPoints(player)}</td>
                                <td>{getSwords(player)}</td>
                                <td>{player.cards.filter(card => card.type === Type.SETTLER).length}</td>
                                <td>{player.cards.filter(card => card.type === Type.PRIEST).length}</td>
                                <td>{player.cards.filter(card => card.type === Type.CAPTAIN).length}</td>
                                <td>{player.cards.filter(card => card.type === Type.JACK_OF_ALL_TRADES).length}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table>
                        <thead>
                            <tr>
                                <th>A</th>
                                <th>G</th>
                                <th>J</th>
                                <th>M</th>
                                <th>T</th>
                                <th>E</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{player.cards.filter(card => card.type == Type.ADMIRAL).length}</td>
                                <td>{player.cards.filter(card => card.type == Type.GOVERNOR).length}</td>
                                <td>{player.cards.filter(card => card.type == Type.JESTER).length}</td>
                                <td>{player.cards.filter(card => card.type == Type.MADEMOISELLE).length}</td>
                                <td>
                                    {Object.keys(cardGroups)
                                        .map((color) => (
                                            <span className={"player-info__trader"} key={color} style={{
                                                color: `${color}`
                                            }}>
                                                {cardGroups[color]}
                                            </span>
                                        ))
                                    }
                                </td>
                                <td>{player.cards.filter(card => card.type == Type.EXPEDITION).length}</td>
                            </tr>
                        </tbody>
                    </table>
                    {/*<p>Cards: {cardTypes}</p>*/}

                </Card>
            </div>
            <div className="middle">
                <PlayerInfoCardView playerCards={player.cards}/>
            </div>
        </div>
    );
};

export default PlayerInfo;