import React, { useEffect, useState } from 'react';
import { joinGame } from '../../server/RestApiClient';
import { useNavigate, useParams } from 'react-router-dom';
import { useGame } from "../../GameContext";
import Onboarding from '../../layouts/Onboarding/Onboarding';
import { Button } from 'react-bootstrap';

function JoinGameForm() {
    const navigate = useNavigate();
    const { gameIdParam } = useParams();
    const [gameIdToJoin, setGameIdToJoin] = useState('');
    const [playerName, setPlayerName] = useState('');
    const [password, setPassword] = useState('');
    const { setGameState, setGameId, setPlayer } = useGame();

    async function joinAGame() {
        if (gameIdToJoin != null) {
            if(window.startMusic) window.startMusic();
            
            let gameDTO = await joinGame(gameIdToJoin, playerName, password);
            localStorage.setItem('gameId', gameDTO.id);
            setGameId(gameDTO.id);
            setGameState(gameDTO);
            localStorage.setItem('playerName', playerName);
            setPlayer(gameDTO.players.find(value => value.name === playerName) ?? null)
            // Navigate to the LobbyPage with the gameId as a parameter
            navigate(`/lobby`);
        }
    }

    useEffect(() => {
        if (gameIdParam) {
            setGameIdToJoin(gameIdParam);
        }
    }, []);

    const menuItems = [
        (<input disabled={!!gameIdParam} className="onboarding__input" type="text" placeholder="game id" value={gameIdToJoin} onChange={(e) => setGameIdToJoin(e.target.value)} />),
        (<input className="onboarding__input" type="text" placeholder="Enter your name" value={playerName} onChange={(e) => setPlayerName(e.target.value)} />),
        (<input className="onboarding__input" type="text" placeholder="Enter a password" value={password} onChange={(e) => setPassword(e.target.value)} />),
        (<Button className="onboarding__button" variant="outline-dark" onClick={joinAGame}>Join Game</Button>)
    ];

    return (
        <Onboarding menuItems={menuItems} />
    );
}

export default JoinGameForm;
