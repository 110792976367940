import CardDTO, {Type} from "./CardDTO";
import GameActionDTO, {ActionType} from "./GameActionDTO";
import cardDTO from "./CardDTO";

interface PlayerDTO {
    id: string;
    noCoins: number;
    cards: CardDTO[];
    name: string;
    mainPlayer: boolean;
    secondaryPlayer: boolean;
    remainingActions: number;
    availableActions: GameActionDTO[];
    winner: boolean;
}


function getVictoryPoints(player: PlayerDTO): number {
    if (player=== null) {
        return 0;
    }
    if (player.cards.length < 1) {
        return 0;
    }
    return player.cards
        .map(card => card.victoryPoints)
        .reduce((previousValue, currentValue) => {
            if (!previousValue) {
                return currentValue;
            }
            return previousValue + currentValue;
        });

}

function getSwords(player: PlayerDTO): number {
    return player.cards
        .filter(card => card.type === Type.SAILOR || card.type === Type.PIRATE)
        .reduce((total, card) => {
            if (card.type === Type.PIRATE) {
                return total + 2;
            } else if (card.type === Type.SAILOR) {
                return total + 1;
            } else {
                return total;
            }
        }, 0);
}

export {getVictoryPoints, getSwords}
export default PlayerDTO;
