import React from 'react';
import CardDTO, {Type} from '../../../models/CardDTO';

interface CardProps {
    gameCard: CardDTO;
}

const GameCard: React.FC<CardProps> = ({gameCard}) => {
    const cardImageFileName = getCardImageFileName(gameCard);

    return (
        <img
            src={`/card_images/${cardImageFileName}`} // Update the path accordingly
            alt={gameCard.id}

            className="resizable-card"
        />
    );
};

// Helper function to get the image file name based on CardDTO properties
const getCardImageFileName = (card: CardDTO): string => {
    switch (card.type) {
        case Type.SHIP:
            return `/ships/ship_${card.color.toLowerCase()}_reward_${card.reward}_swords_${card.noSwords}.png`;
        case Type.TRADER:
            return `/persons/trader_${card.color.toLowerCase()}_cost_${card.cost}_points_${card.victoryPoints}.png`;
        case Type.EXPEDITION:
            const reqStrings = card.requirements
                .map(value => value.toLowerCase())
                .sort()
                .join('_'); // Join the sorted requirements with underscores
            return `/expeditions/expedition_${reqStrings}.png`;
        case Type.TAX_INCREASE:
            return `/tax_increases/tax_increase_${card.taxReliefCondition.toLowerCase()}.png`;
        case Type.JACK_OF_ALL_TRADES:
        case Type.CAPTAIN:
        case Type.PRIEST:
        case Type.SETTLER:
        case Type.ADMIRAL:
        case Type.GOVERNOR:
        case Type.JESTER:
        case Type.MADEMOISELLE:
        case Type.PIRATE:
        case Type.SAILOR:
            return `/persons/${card.type.toLowerCase()}_cost_${card.cost}_points_${card.victoryPoints}.png`;
        default:
            console.error("Enum mapping broken!");
            return '';
    }
};

export default GameCard;