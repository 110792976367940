import React from 'react';
import Button from 'react-bootstrap/Button';
import {executeGameAction} from "../../../server/RestApiClient";
import {useGame} from "../../../GameContext";
import GameActionDTO, {displayValue} from "../../../models/GameActionDTO";
import './PlayerActions.css';

interface PlayerActionsProps {
    availableActions: GameActionDTO[];
}

const PlayerActions: React.FC<PlayerActionsProps> = ({ availableActions }) => {
    const { gameId, setGameState } = useGame();

    const executeAction = async (action: GameActionDTO) => {
        if (gameId !== null) {
            let gameDTO = await executeGameAction(gameId, action);
            setGameState(gameDTO);
        }
    }

    return (
        <div>
            <div className="player-actions-container">
                {availableActions
                    .filter(action => action.referencedCardId === null || action.referencedCardId === undefined)
                    .map((action) => (
                        <div className="player-action-btn" key={action.id}>
                            <Button
                                variant={action.actionType.startsWith("END") ? "danger" : "success"}
                                onClick={() => executeAction(action)}
                            >
                                {displayValue(action.actionType)}
                            </Button>
                        </div>

                    ))}
            </div>
        </div>
    );
}

export default PlayerActions;
