import React, {useEffect, useState} from 'react';
import Header from '../../components/Header/Header';
import GameBoard from '../../components/game/Gameboard/GameBoard';
import PlayerList from "../../components/game/PlayerInfo/PlayerList";
import {useGame} from "../../GameContext";
import PlayerDTO from "../../models/PlayerDTO";
import {executeGameAction, getGameState} from "../../server/RestApiClient";
import GameActionDTO from "../../models/GameActionDTO";
import {useWebSocket} from "../../server/WebSocketProvider";
import "./GamePage.css";
import BottomMenu from '../../components/BottomMenu/BottomMenu';
import GameEventList from '../../components/game/EventLog/GameEventList';

interface WinnerInfo {
    name: string;
    victoryPoints: number;
    noCoins: number;
    isWinner: boolean;
}

function GamePage() {
    const {gameId, setGameId, gameState, player, setGameState, setPlayer} = useGame();
    const [players, setPlayers] = useState<PlayerDTO[]>([]);

    const [showModal, setShowModal] = useState(false);
    const [winners, setWinners] = useState<WinnerInfo[]>([]);

    const {stompClient} = useWebSocket();
    const [loading, setLoading] = useState(true);

    // Check winners
    useEffect(() => {
        if (gameState) {
            setPlayers(gameState.players);
            setPlayer(gameState.players.find(value => value.id === player?.id) ?? player)

            const gameWinners = gameState.players.filter(player => player.winner);

            if (gameWinners.length > 0) {
                // Set the modal content here
                const winnerInfo: WinnerInfo[] = gameWinners.map(winner => ({
                    name: winner.name,
                    victoryPoints: winner.cards
                        .map(card => card.victoryPoints)
                        .reduce((prev, current) => prev + current, 0),
                    noCoins: winner.noCoins,
                    isWinner: winner.winner,
                }));

                setWinners(winnerInfo);
                setShowModal(true); // Show the modal
            }
        }
    }, [gameState, player, setPlayer]);

    async function fetchGameState() {
        // TODO: Use socket endpoint for this?
        const storedGameId = await localStorage.getItem('gameId');
        console.log("GameId: ", storedGameId); // Here we print the actual gameId

        if (storedGameId) {
            console.log("Fetching game state...");
            let gameState = await getGameState(storedGameId);
            setGameId(storedGameId);
            console.log("Game id: " + gameId); //  Here the gameId is null
            setGameState(gameState);
            let playerName = localStorage.getItem('playerName');
            setPlayer(gameState.players.find(value => value.name === playerName) ?? null)
            console.log("Game state fetched!");
        }
    }

    async function establishWebSocketConnection(): Promise<void> {
        if (!stompClient.connected) {
            console.log('Re-establishing WebSocket connection...');

            // Create a Promise that resolves when the connection is established
            const connectionPromise = new Promise<void>((resolve) => {
                stompClient.onConnect = () => {
                    console.log('WebSocket connection established.');
                    resolve(); // Resolve the Promise when connected
                };
            });

            // Activate the WebSocket client
            stompClient.activate();

            // Wait for the connection to be established
            await connectionPromise;
            setLoading(false);
            console.log('Stomp client connected.');
        } else {
            setLoading(false);
        }
    }

    function subscribe() {
        let localGameId = localStorage.getItem("gameId");
        console.log("Subscribing to updates for game" + localGameId);
        stompClient.subscribe(`/socket/game/${localGameId}/update`, (message) => {
                console.log("Received game data for gameId: ", localGameId);
                const gameData = JSON.parse(message.body);
                // Handle game data update (e.g., update UI)
                setGameState(gameData);
            },
            {id: "lobby-page-subscription"});
    }

    useEffect(() => {
        setLoading(true)
        establishWebSocketConnection()
            .then(fetchGameState)
            .then(subscribe)
            .then(() => setLoading(false));

        return () => {
            if (stompClient.connected) {
                stompClient.unsubscribe(`/socket/game/${gameId}/update`);
            }
        }
    }, []);

    // Use socket?
    const executeAction = async (action: GameActionDTO) => {
        if (gameState !== null && gameState?.id !== null) {
            let gameDTO = await executeGameAction(gameState?.id, action);
            setGameState(gameDTO);
        }
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    console.log(player);

    return (
        <div className="game-page">
            <div className="game-page__content">
                <Header/>
                <div className="game-page-div">
                    <div className="game-board-div">
                        <GameBoard
                            game={gameState}
                            availableActions={player?.availableActions ?? []}
                            onCardClick={executeAction}/>
                    </div>
                    <div className="players-list-div">
                        <PlayerList players={players}/>
                    </div>
                </div>

                {showModal && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={() => setShowModal(false)}>
                                &times;
                            </span>
                            <h2 className="winner-modal-header modal-header">Players Win!</h2>
                            {/* Display the modal content */}
                            {winners.map((winner, index) => (
                                <div key={index}>
                                    <p className="winner-modal-text modal-body">Name: {winner.name}</p>
                                    <p className="winner-modal-text">Victory Points: {winner.victoryPoints}</p>
                                    <p className="winner-modal-text">No. of Coins: {winner.noCoins}</p>
                                    <p className="winner-modal-text">Winner: {winner.isWinner ? 'Yes' : 'No'}</p>
                                    {index < winners.length - 1 && <hr/>}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div>
            <GameEventList gameEvents={gameState?.gameEvents ?? []} players={players} />
            </div>

            {(player && player?.availableActions.length > 0) && (
                <BottomMenu availableActions={player.availableActions}/>
            )}

        </div>
    );
}

export default GamePage;