import { useState } from 'react';
import './MusicToggleButton.css'; 

const MusicToggleButton = () => {
    const backgroundMusic = document.getElementById('backgroundMusic') as HTMLAudioElement;
    const [isPlaying, setIsPlaying] = useState(!backgroundMusic.paused);

    // Check if backgroundMusic is playing
    const toggleMusic = () => {
        if (isPlaying) {
            window.stopMusic();
        } else {
            window.startMusic();
        }
        setIsPlaying(!isPlaying);
    };

    return (
        <button onClick={toggleMusic} className="sticky-button">
            {isPlaying ?
                <img width="32px" src="/unmute.png" /> : <img width="32px" src="/mute.png" />
            }
        </button>
    );
};


export default MusicToggleButton;