import React from 'react';
import GameDTO from "../../../models/GameDTO";
import GameCard from "../GameCard/GameCard"; // Adjust the import path as needed
import './GameBoard.css';
import GameActionDTO, { displayValue } from "../../../models/GameActionDTO";
import Button from "react-bootstrap/Button";
import GameEventList from "../EventLog/GameEventList";


interface GameBoardProps {
    game: GameDTO | null;
    availableActions: GameActionDTO[]; // Pass available actions as a prop
    onCardClick: (gameAction: GameActionDTO) => void; // Define a click handler
}

function GameBoard({ game, availableActions, onCardClick }: GameBoardProps) {
    if (game === null) {
        return <div></div>

    }

    return (
        <div className="game-board">
            {/* Render the harbor information */}
            <h3>Harbor Cards</h3>
            <div className="harbor-cards-container">
                {game.harbor.cards.map((card) => {
                    let matchingAction = availableActions.find((action) => action.referencedCardId === card.id);
                    return (
                        <div
                            key={card.id}
                            className={`card-container ${availableActions.some((action) => action.referencedCardId === card.id)
                                    ? 'highlighted'
                                    : ''
                                }`}
                        >
                            <GameCard gameCard={card} />
                            {matchingAction && (
                                <Button variant="primary"
                                    onClick={() => {
                                        if (matchingAction) {
                                            onCardClick(matchingAction); // Handle the action
                                        }
                                    }}
                                >
                                    {displayValue(matchingAction.actionType)}
                                </Button>
                            )}
                        </div>
                    );
                })}

            </div>

            {/* Render the expeditions information */}
            <h3>Expeditions</h3>
            <div className="expedition-cards-container wooden-frame">
                {game.expeditions.map((card) => {
                    let matchingAction = availableActions.find((action) => action.referencedCardId === card.id);
                    return (
                        <div
                            key={card.id}
                            className={`card-container ${availableActions.some((action) => action.referencedCardId === card.id)
                                    ? 'highlighted'
                                    : ''
                                }`}
                        >
                            <GameCard gameCard={card} />
                            {matchingAction && (
                                <Button variant="primary"
                                    onClick={() => {
                                        if (matchingAction) {
                                            onCardClick(matchingAction); // Handle the action
                                        }
                                    }}
                                >
                                    {displayValue(matchingAction.actionType)}
                                </Button>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default GameBoard;