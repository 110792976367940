import React, { ReactHTMLElement, useState } from 'react';
import { createGame } from '../../server/RestApiClient';
import { useNavigate } from 'react-router-dom';
import { useGame } from "../../GameContext";
import backgroundImage from "../../assets/home-page-background.jpeg";
import Button from "react-bootstrap/Button";
import Onboarding from '../../layouts/Onboarding/Onboarding';


function CreateGameForm() {
    const navigate = useNavigate();
    const [playerName, setPlayerName] = useState('');
    const [password, setPassword] = useState('');
    const { gameId, player, gameState, setGameState, setGameId, setPlayer } = useGame();

    async function createNewGame() {
        let gameDTO = await createGame(playerName, password);
        localStorage.setItem('gameId', gameDTO.id);
        setGameId(gameDTO.id);
        setGameState(gameDTO);
        setPlayer(gameDTO.players.at(0) ?? null)
        localStorage.setItem('playerName', playerName);
        // Navigate to the LobbyPage with the gameId as a parameter
        navigate(`/lobby`);
    }

    const menuItems = [
        (<input className="onboarding__input"
            type="text"
            placeholder="Enter your name"
            value={playerName}
            onChange={(e) => setPlayerName(e.target.value)}
        />),
        (<input
           className='onboarding__input'
            type="text"
            placeholder="Enter a password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
        />),
        (<Button className="onboarding__button" variant="outline-dark" onClick={createNewGame}>Create Game</Button>)
    ];

    return (
        <>
            <Onboarding menuItems={menuItems} />
        </>
    );
}

export default CreateGameForm;
